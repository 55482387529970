import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Container, Row, Col } from 'react-bootstrap';

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import 'bootstrap/dist/css/bootstrap.css'


const Photos2021IndexPage = ({data}) => (
  <Layout>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url('/img/ivory-bill-jumbotron.jpg')`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: '0.5rem 0 100 0, -0.5rem 0 100 0',
          backgroundColor: 'rgb(0, 100, 0)',
          color: 'white',
          padding: '1rem',
        }}
      >
        Photos from the 2021 Neches River Rally
      </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content">
	  <h2>Photos by volunteer Tara Nichole Bryant, and by John Stafford where noted in full size view.</h2>
          {/* Bootstrap works off of a 12-column */}
          <Container>
            <SimpleReactLightbox>
              <SRLWrapper>
                <Row>
                  {data.gallery.edges.map(({node}) => (
                    <Col lg={4} md={6} key={node.id} className="py-3">
                      {/* {node.base.split('-').join(' ').split('.')[0]} */}
                      <a href={node.publicURL}>
                        <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('-').join(' ').split('.')[0]} />
                      </a>
                    </Col>
                  ))}
                </Row>
              </SRLWrapper>
            </SimpleReactLightbox>
          </Container>
        </div>
      </div>
    </section>
  </Layout>
)

export default Photos2021IndexPage

export const pageQuery = graphql`
  query {
    gallery: allFile(filter: {
      relativeDirectory: {eq: "rally2021"}}
      sort: {fields: base, order: ASC}) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            gatsbyImageData(
              height: 600
              width: 900
              transformOptions: {fit: COVER}
              placeholder: BLURRED
              webpOptions: {quality: 50}
            )
          }
        }
      }
    }
  }
`
